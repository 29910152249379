import { usePartnerFetchRepairInfoApi } from 'api/partner/repair_info'
import DefectReportDataCard from 'components/partner/defect_report/defect_report_data_card'
import InstrumentDataCard from 'components/partner/instrument/instrument_data_card'
import RepairEstimateDataCard from 'components/partner/repair_estimate/repair_estimate_data_card'
import RepairEventDataCard from 'components/partner/repair_event/repair_event_data_card'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { Flex } from 'components/shared/flex'
import Footer from 'components/shared/footer'
import StatusLabel from 'components/shared/status_label'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { DefectReport } from 'entities/defect_report'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEvent } from 'entities/repair_event'
import React, { useContext, useEffect, useState } from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { PartnerRouteHelper } from 'routes/partner'
import { RepairEventViewModel } from 'view_models/repair_event'

type Props = RouteComponentProps<{ id: string }, StaticContext>
export const RepairInfoShow: React.FC<Props> = (props) => {
  const fetchApi = usePartnerFetchRepairInfoApi()
  const partnerState = useContext(PartnerStateContext)
  const globalState = useContext(GlobalStateContext)
  const history = useHistory()
  const [vm, setVm] = useState<RepairEventViewModel>()

  useEffect(() => {
    fetchApi.execute(Number(props.match.params.id))
  }, [])

  useEffect(() => {
    globalState.setLoading(fetchApi.loading)
    if (fetchApi.isSuccess() && fetchApi.response.repairInfo.repairEvent) {
      setVm(new RepairEventViewModel(fetchApi.response.repairInfo.repairEvent))
    }
  }, [fetchApi.loading])

  const handleClickLinkProofreadEvent = (proofreadEvent: ProofreadEvent | undefined) => {
    const planId = proofreadEvent?.proofreadPlanId
    if (planId) {
      props.history.push(PartnerRouteHelper.proofreadPlanShow(planId))
    }
  }

  const handleComplete = () => {
    fetchApi.execute(Number(props.match.params.id))
  }

  const handleCompleteDefectReportEdit = (report?: DefectReport) => {
    if (report) {
      fetchApi.execute(Number(props.match.params.id))
    } else {
      // 削除時は一覧画面にリダイレクト
      history.push(PartnerRouteHelper.defectReportIndex())
    }
  }

  const handleCompleteRepairEstimateEdit = () => {
    fetchApi.execute(Number(props.match.params.id))
  }

  const handleCompleteRepairEventEdit = (event?: RepairEvent) => {
    if (event) {
      fetchApi.execute(Number(props.match.params.id))
    } else {
      // 削除された場合
      props.history.push(PartnerRouteHelper.repairEventIndex())
    }
  }

  const canEdit = () => {
    const proofreadEvent = fetchApi.response.repairInfo.repairEvent?.proofreadEvent
    if (proofreadEvent && (proofreadEvent.aasmState === 'fix_result' || proofreadEvent?.aasmState === 'approved_result')) {
      return false
    } else {
      return true
    }
  }

  const isShared = fetchApi.response.repairInfo.instrument?.sharingState === 'sharing'

  return (
    <>
      {fetchApi.isSuccess() && (
        <>
          <ContentHeader>
            <ContentTitle title="修理情報詳細">{vm && <StatusLabel text={vm.getResultText()} color={vm.getResultColor()} />}</ContentTitle>
          </ContentHeader>

          <Flex style={{ marginBottom: 60 }}>
            {fetchApi.response.repairInfo.instrument && <InstrumentDataCard instrument={fetchApi.response.repairInfo.instrument} />}
            <Flex flexDirection="column" style={{ flexBasis: '100%' }}>
              <DefectReportDataCard
                defectReport={fetchApi.response.repairInfo.defectReport}
                canEdit={!fetchApi.response.repairInfo.repairEstimate && !fetchApi.response.repairInfo.repairEvent}
                onCompleteEdit={handleCompleteDefectReportEdit}
                onCompleteCreateEvent={handleComplete}
                onCompleteCreateEstimate={handleComplete}
                emptyMessage="不具合報告が存在しません"
                isShared={isShared}
              />
              {
                //機器共有機能ONの場合のみ表示
                partnerState.currentDealer.authority?.enableInstrumentSharing && (
                  <RepairEstimateDataCard
                    repairEstimate={fetchApi.response.repairInfo.repairEstimate}
                    onComplete={handleCompleteRepairEstimateEdit}
                    emptyMessage="修理見積が存在しません"
                  />
                )
              }
              <RepairEventDataCard
                repairEvent={fetchApi.response.repairInfo.repairEvent}
                canEdit={canEdit()}
                onComplete={handleCompleteRepairEventEdit}
                emptyMessage="修理履歴が存在しません"
              />
            </Flex>
          </Flex>

          <Footer
            content={
              <Flex>
                {fetchApi.response.repairInfo.proofreadEvent && (
                  <ThemedButton color="primary" onClick={() => handleClickLinkProofreadEvent(fetchApi.response.repairInfo.proofreadEvent)}>
                    校正計画
                  </ThemedButton>
                )}
                <ThemedButton color="secondary" onClick={history.goBack}>
                  戻る
                </ThemedButton>
              </Flex>
            }
          />
        </>
      )}
    </>
  )
}

export default withRouter(RepairInfoShow)
